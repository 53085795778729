<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { type DataItemModel } from "o365-dataobject";

    export interface IProps {
        score: number,
        editable: boolean
    };

    const props = defineProps<IProps>();
    const emit = defineEmits(['setScore']);

    const hoveredScore = ref<number>(0);

    function setHoveredScore(star: number) {
        if (!props.editable) return;
        hoveredScore.value = star;
    }
</script>

<template>
    <div class="d-flex gap-2">
        <div>
            <template v-if="editable">
                <template v-for="star in 5">
                    <i v-if="star <= score" 
                        class="bi bi-star-fill" 
                        :class="star == hoveredScore ? 'text-primary' : 'text-warning'"
                        @click="emit('setScore', star);"
                        @mouseover="setHoveredScore(star)" @mouseleave="setHoveredScore(0)"
                        :style="{ cursor: editable ? 'pointer' : 'default' }">
                    </i>
                        
                    <i v-else 
                        :class="star < hoveredScore ? 'bi bi-star-fill text-warning opacity-25' : star == hoveredScore ? 'bi bi-star-fill text-primary' : 'bi bi-star text-warning'" 
                        @click="emit('setScore', star);"
                        @mouseover="setHoveredScore(star)" @mouseleave="setHoveredScore(0)"
                        :style="{ cursor: editable ? 'pointer' : 'default' }">
                    </i>
                </template> 
            </template>

            <template v-else>
                <template v-for="star in 5">
                    <!-- full stars -->
                    <i v-if="star <= Math.floor(score)" class="bi bi-star-fill text-warning"></i>

                    <!-- partially filled stars -->
                    <span v-else-if="star == Math.ceil(score) && score % 1 != 0" class="partially-filled-star-container text-warning">
                        <i class="bi bi-star"></i>
                        <i class="bi bi-star-fill partially-filled-star" :style="{ 'width': `${(score % 1) * 100}%`}"></i>
                    </span>

                    <i v-else class="bi bi-star text-warning"></i>
                </template>
            </template>
        </div>

        <div title="Assessment"> {{ score ?? 0 }}</div>
    </div>
</template>

<style scoped>
    .partially-filled-star-container {
        position: relative;
        display: inline-block;
    }

    .partially-filled-star {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
</style>